/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface ModelSplash {
  show: boolean;
}
const initSplash: ModelSplash = {
  show: true,
};

export const sliceSplash = createSlice({
  name: 'splash',
  initialState: initSplash,
  reducers: {
    setSplash: (state, action: { payload: ModelSplash }) => {
      state.show = action.payload.show;
    },
  },
});

export const {
  setSplash,
} = sliceSplash.actions;
