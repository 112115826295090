import { configureStore } from '@reduxjs/toolkit';
import { sliceCalculatorInput } from './sliceCalculatorInput';
import { sliceSplash } from './sliceSplash';

const store = configureStore({
  reducer: {
    calculatorInput: sliceCalculatorInput.reducer,
    splash: sliceSplash.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
