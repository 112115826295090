import { useSinglePrismicDocument } from '@prismicio/react';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { asText } from '@prismicio/helpers';
import VideoBackground from './VideoBackground';
import { GlobalMetaDocument, Home2023Document } from '../../Vendors/prismic.types.generated';
import {
  BASE_URL, META_DESCRIPTION, META_IMAGE, SITE_NAME,
} from '../../constant';
import Underwritters from './Underwritters';
import Hero from './Hero';
import Splash from './Splash';

export default function Home() {
  const [meta] = useSinglePrismicDocument<GlobalMetaDocument>('global_meta');
  const [home] = useSinglePrismicDocument<Home2023Document>('home_2023');

  let title = SITE_NAME;
  let description = META_DESCRIPTION;
  let image = META_IMAGE;

  if (meta) {
    title = asText(meta.data.seo_title);
    description = asText(meta.data.seo_description);
    image = meta.data.seo_image?.url || '';
  }
  // console.log('render home');
  return (
    <div className="home">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${image}&w=1200` || ''} />
        <meta property="og:url" content={`${BASE_URL}/`} />
      </Helmet>
      <Splash />
      <div className="hero">
        <VideoBackground
          video={home?.data.hero_video_url || ''}
        >
          <Container>
            <Row className="justify-content-center">
              <Col xl={9} sm={12}>
                <Hero home={home} />
                <div className="tagline">{home?.data.persistent_tagline}</div>
                <Button type="button">{home?.data.call_to_action}</Button>
              </Col>
            </Row>
          </Container>
        </VideoBackground>
      </div>
      <Underwritters />
    </div>
  );
}
