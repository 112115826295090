import React, { useRef, useState } from 'react';
import Cover from 'react-video-cover';

function VideoBackground({ children, video }: { children: JSX.Element, video: string }) {
  const [icon, setIcon] = useState('fa-volume-xmark');
  const videoElement = useRef<null | HTMLVideoElement>(null);
  const videoOptions = {
    src: video,
    autoPlay: true,
    muted: true,
    loop: true,
    ref: (videoRef: HTMLVideoElement) => { videoElement.current = videoRef; },
    playsInline: true,
  };
  const toggleMusic = () => {
    if (videoElement?.current) {
      videoElement.current.muted = !videoElement.current.muted;
      setIcon(videoElement.current.muted ? 'fa-volume-xmark' : 'fa-volume-high');
    }
  };
  return (
    <div className="video-background">
      <div className="video-cover">
        <Cover
          videoOptions={videoOptions}
          remeasureOnWindowResize
        />
      </div>
      <button type="button" onClick={toggleMusic} className="as-text music-toggle">
        <span className={`fas ${icon}`} />
      </button>
      {children}
    </div>
  );
}

export default VideoBackground;
